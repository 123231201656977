import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useAuth } from '../../auth/AuthProvider';
import { AuthState } from '../../auth/authMachine';
import { LoggedInHeader } from './loggedInHeader';
import { LoggedOutHeader } from './loggedOutHeader';

interface HeaderDynamicProps extends RouteComponentProps {
  loggedOutVariant?: 'light' | 'transparent' | 'transparentBlackText';
  keepPathOnLogin?: boolean;
  jumpLink?: string;
  compact?: boolean;
}

const HeaderDynamic: React.FunctionComponent<HeaderDynamicProps> = ({
  loggedOutVariant,
  keepPathOnLogin,
  jumpLink,
  compact,
  ...forwardProps
}) => {
  const { authState } = useAuth();

  return authState.matches(AuthState.isLoggedIn) ? (
    <LoggedInHeader compact={compact} {...forwardProps} />
  ) : authState.matches(AuthState.isNotLoggedIn) ? (
    <LoggedOutHeader
      variant={loggedOutVariant || 'light'}
      keepPathOnLogin={keepPathOnLogin}
      jumpLink={jumpLink}
      compact={compact}
      {...forwardProps}
    />
  ) : null;
};

export { HeaderDynamic };
